import React from "react";
import { Link } from "gatsby";
import { rhythm, scale } from "../utils/typography";
import styled from "styled-components";
import Navigation from "./navigation";
import Footer from "./footer";

class Layout extends React.Component {
  render() {
    const { title, children } = this.props;
    let header;
    const LayoutContainer = styled.div`
      margin-left: 200px;
      padding: ${rhythm(2)};
      max-width: 675px;

      @media (max-width: 768px) {
        margin: 150px auto 100px;
        padding: ${rhythm(1)};
        ${{ ...scale(-0.2) }};
      }
    `;
    const Main = styled.main`
      max-width: 800px;
    `;

    header = (
      <h1
        style={{
          ...scale(1),
          marginTop: 0,
        }}
      >
        {title}
      </h1>
    );
    return (
      <LayoutContainer>
        <Navigation />
        <header>{header}</header>
        <Main>{children}</Main>
        <Footer />
      </LayoutContainer>
    );
  }
}

export default Layout;
