import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import styled from "styled-components";
import { rhythm, scale } from "../utils/typography";
import 'normalize.css'
import '../styles/global.css'

const EmailContainer = styled.div`
  display: block;

  :hover {
    input[type="submit"] {
      background-color: var(--Pink--Light);
      cursor: pointer;
      cursor: hand;
    }
  }

  > form {
    border-radius: 2px;
    margin: 0;
    padding-bottom: ${rhythm(1)};

    input {
      border-radius: 3px;
      border: 1px solid var(--Light-Black);
      padding: ${rhythm(1 / 10)} ${rhythm(1 / 2)};
    }

    input[type="email"] {
      width: 66%;
      @media (max-width: 767px) {
        width: 63%;
        ${{ ...scale(-0.5) }};
      }
    }
    input[type="submit"] {
      background-color: var(--Pink);
      transition: all 0.2s linear;
      @media (max-width: 767px) {
        ${{ ...scale(-0.5) }};
      }
    }
  }
`;

const Warning = styled.div`
  ${{ ...scale(-0.5) }};
  margin-top: ${rhythm(0.25)};
  width: 66%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const InputText = styled.input.attrs(props => ({
  type: "email",
  value: props.value,
  onChange: props.changeVal,
}))``;


class EmailSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", result: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ email: event.target.value });
  }
  handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email);
    this.setState({ result: result, email: "" });
  };

  render() {
    const result = this.state.result;
    let emailMessage;
    if (result) {
      if (result.result === "error") {
        emailMessage = (
          <div
            className="error-msg"
            dangerouslySetInnerHTML={{ __html: this.state.result.msg }}
          />
        );
      } else {
        emailMessage = (
          <div
            className="success-msg"
            dangerouslySetInnerHTML={{ __html: this.state.result.msg }}
          />
        );
      }
    } else {
      emailMessage = "";
    }

    return (
      <EmailContainer>
        <form onSubmit={this.handleSubmit}>
          <InputText value={this.state.email} changeVal={this.handleChange} />
          <input label="Submit" type="submit" value="Subscribe" />
          <Warning className="email-message">{emailMessage}</Warning>
        </form>
      </EmailContainer>
    );
  }
}

export default EmailSignup;
